<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
        <h1>Hinweise zum Datenschutz</h1>
        <p>Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. Mit dieser Datenschutzerklärung erläutern
          wir,
          welche Art von Daten wir bei Ihrem Besuch des Portal Vermessungsunterlagen NRW erheben und zu welchem Zweck
          wir
          sie
          verwenden.</p>
        <h2>Datenschutzbestimmungen</h2>

        <p>Als Teil der Landesverwaltung unterliegen wir den Bestimmungen des Nordrhein-Westfälischen
          Datenschutzgesetzes sowie der EU-Datenschutzgrundverordnung. Wir stellen sicher, dass die
          Vorschriften über den Datenschutz auch von beteiligten externen Dienstleistern beachtet
          werden.</p>
        <h2>Datenübertragung</h2>

        <p>Das Fernmeldegeheimnis schützt grundsätzlich auch Ihre Mail-Inhalte und Formulareingaben
          vor unbefugter Kenntnisnahme und Verarbeitung. Seine Beachtung können wir für den Bereich
          des Landesbetriebs Information und Technik Nordrhein-Westfalen sicherstellen. Wir weisen Sie
          aber darauf hin, dass die Datenübertragungen über das Internet generell von anderen
          Internet-Betreibern und Nutzern aufgezeichnet werden können. Personenbezogene Daten werden
          deshalb nach Möglichkeit nur verschlüsselt an uns übertragen.</p>
        <h2>Automatische Speicherung von Daten</h2>

        <p>Die folgenden Daten werden von uns bei jedem Aufruf der Seiten gespeichert:</p>

        <ul>
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>Name des aufgerufenen Internetdienstes</li>
          <li>Name der aufgerufenen Ressource</li>
          <li>Name der verwendeten Aktion</li>
          <li>Abfrage, die der Client gestellt hat</li>
          <li>übertragene Datenmenge</li>
          <li>Meldung, ob der Abruf erfolgreich war</li>
          <li>IP-Adresse des aufrufenden Rechners</li>
        </ul>

        <p>Die gespeicherten Daten werden zum Zweck der statistischen Auswertung über die Nutzung
          dieser Internetpräsenz erhoben und anonymisiert zusammengefasst und 6 Wochen vorgehalten.
          Weiterhin nutzen wir sie zur Abwehr beziehungsweise Analyse von Angriffen auf unser
          Webangebot.</p>

        <p>Darüber hinaus werden personenbezogene Daten (z. B. Ihr Name, Ihre E-Mail-Adresse usw.) nur
          dann an uns übermittelt, wenn Sie uns ausdrücklich und wissentlich solche Informationen für
          bestimmte Zwecke zur Verfügung stellen. Sie werden nur in dem Umfang verarbeitet,
          gespeichert und weitergeleitet, wie es für den jeweiligen Zweck erforderlich ist oder Ihre
          Einwilligung vorliegt.</p>
        <h2>Erhebung und Speicherung personenbezogener Daten sowie
          Art und Zweck von deren Verwendung</h2>


        <p>Zum Zwecke der Datenbereitstellung ist die Angabe einer E-Mail-Adresse notwendig, an welche der Download-Link
          für
          den Datenzugriff gesendet wird. Diese E-Mail-Adresse wird ausschließlich für die Datenbereitstellung
          gespeichert
          und nach längstens 24 Stunden gelöscht.</p>
        <p>Ein Widerruf der Einwilligung der Speicherung ist aufgrund der Kürze der Aufbewahrungsfrist nicht möglich.</p>


        <h2>Nutzung von Cookies</h2>

        <p>Das Portal Vermessungsunterlagen NRW verwendet sogenannte &bdquo;Cookies&ldquo;. Bei Cookies handelt es sich
          um
          kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
          Diese werden fehlerfreie Nutzung des Portals benötigt.</p>

        <p>Sie können die Verwendung von Cookies unterbinden, indem Sie Ihren Internetbrowser so
          einstellen, dass dieser keine Cookies akzeptiert. Sie können in Ihrem Browser aber auch
          festlegen, ob Sie über das Setzen von Cookies informiert werden möchten und Cookies nur im
          Einzelfall erlauben, ob Sie die Annahme von Cookies für bestimmte Fälle oder eben auch
          generell ausschließen. Außerdem können Sie das automatische Löschen der Cookies beim
          Schließen des Browsers aktivieren. Diese Einstellungen hängen von Ihrem Browser ab.</p>


        <h2>Kontaktdaten der für die Verarbeitung Verantwortlichen
          Stelle sowie der Datenschutzbeauftragen</h2>

        <p>Verantwortlich für die Datenverarbeitung ist</p>

        <p>Landesbetrieb<br/>
          Information und Technik Nordrhein-Westfalen (IT.NRW)<br/>
          Mauerstraße 51<br/>
          40476 Düsseldorf<br/>
          Telefon: 0211 9449-01<br/>
          Telefax: 0211 9449-8000<br/>
          <a href="mailto:poststelle@it.nrw.de">poststelle@it.nrw.de</a></p>

        <p>Datenschutzbeauftragte des Landesbetriebes<br/>
          Information und Technik Nordrhein-Westfalen (IT.NRW)<br/>
          Mauerstraße 51<br/>
          40476 Düsseldorf<br/>
          Telefon: 0211 9449-01<br/>
          <a href="mailto:Datenschutzbeauftragte@it.nrw.de">Datenschutzbeauftragte@it.nrw.de</a></p>

        <p>Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte zu:<span class="ml-4"
                                                                                                style="color:red">
      </span></p>

        <ul>
          <li>Recht auf Auskunft über Sie betreffende personenbezogene Daten (Artikel 15
            DatenschutzGrundverordnung)
          </li>
          <li>Recht auf Berichtigung Sie betreffender unrichtiger personenbezogener Daten (Artikel 16
            DatenschutzGrundverordnung)
          </li>
          <li>Recht auf Löschung personenbezogener Daten (Artikel 17 DatenschutzGrundverordnung)</li>
          <li>Recht auf Einschränkung der Verarbeitung personenbezogener Daten (Artikel 18
            DatenschutzGrundverordnung)
          </li>
          <li>Recht auf Widerspruch gegen die Verarbeitung personenbezogener Daten (Artikel 21
            DatenschutzGrundverordnung)
          </li>
        </ul>

        <p>Sie haben nach Artikel 77 Datenschutz-Grundverordnung außerdem das Recht, sich bei der
          Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
          betreffenden personenbezogenen Daten nicht rechtmäßig erfolgt.</p>

        <h2>Aufsichtsbehörde</h2>

        <p>Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br/>
          Postfach 20 04 44<br/>
          40102 Düsseldorf</p>

        <p><a href="http://www.ldi.nrw.de" target="_blank"
              title="Link auf die Seiten der Landesbeauftragten für Datenschutz und Informationsfreiheit Nordrhein-Westfalen">www.ldi.nrw.de</a>
        </p>

        <p>Tel.: 0211/38424-0<br/>
          Fax: 0211/38424-10<br/>
          E-Mail: <a href="mailto:poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</a></p></v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>

export default {

  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {

  }
  ,


}
</script>
